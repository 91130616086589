/**
 * An array of public routes
 * These routes are accessible without authentication
 *
 * @returns string[]
 */
export const publicRoutes: string[] = [
    '/api/base/client',
    '/api/base/check_hash',
    '/api/base/staff/register',
];

/**
 * An array of auth routes
 * These routes are accessible only with authentication
 *
 * @returns string[]
 */
export const authRoutes = [
    '/auth/login',
    '/auth/set-password',
    '/auth/forgot-password',
    '/auth/reset-password',
    '/auth/register',
    // API
    '/api/base/forgot_password',
    '/api/base/reset_password',
    '/api/info',
];

/**
 * The prefix for API authentication routes
 *
 * @returns string
 */
export const apiAuthPrefix = '/api/auth';

/**
 * The default login redirect path
 *
 * @returns string
 */
export const DEFAULT_LOGIN_REDIRECT = '/organization';
