import { Messages } from '@i2e/components';

interface FormErrorProps {
    message?: string;
}

export const FormError = ({ message }: FormErrorProps) => {
    if (!message) return null;

    return (
        <Messages className="mb-6 flex" variant="destructive">
            <p>{message}</p>
        </Messages>
    );
};
