import resourcesToBackend from 'i18next-resources-to-backend';

export * from './client';
export * from './server';
export * from './locale-provider';
export * from './settings';
export * from './helpers';

export { resourcesToBackend };

// Export types
export type { UseTranslationResponse } from 'react-i18next';
export type { Namespace } from 'i18next';
export type { BackendModule, TFunction } from 'i18next';
