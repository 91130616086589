'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, InputText } from '@i2e/components';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    PasswordField,
} from '@i2e/forms';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { useState, useTransition } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useTranslation } from '@/i18n/client';
import { DEFAULT_LOGIN_REDIRECT } from '@/routes';
import { LoginSchema } from '@/schemas/auth';
import { FormError } from '@/ui/form/form-error';

const form = LoginSchema();
type FormField = z.infer<typeof form>;

export function isLoginError(
    obj: { success: string } | { error: string } | undefined,
): obj is { error: string } {
    return obj !== undefined && 'error' in obj;
}

const LoginForm = () => {
    const router = useRouter();
    const [error, setError] = useState<string | undefined>();
    const [isPending, startTransition] = useTransition();
    const { t } = useTranslation('auth');
    const { t: generalT } = useTranslation('general');
    const searchParams = useSearchParams();
    const redirect = searchParams.get('r');

    const form = useForm<FormField>({
        resolver: zodResolver(LoginSchema(t)),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmit = (values: FormField) => {
        setError(undefined);

        startTransition(async () => {
            try {
                const response = await signIn('credentials', {
                    ...values,
                    redirect: false,
                });

                if (!response?.ok) {
                    setError('Invalid credentials');
                    return;
                }

                form.reset();

                router.push(redirect ?? DEFAULT_LOGIN_REDIRECT);
            } catch (error) {
                setError(generalT('common_general_error'));
            }
        });
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormError message={error} />

                <div className="mb-2 space-y-5">
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t('login_form_email')}</FormLabel>
                                <FormControl>
                                    <InputText placeholder={t('login_form_email')} {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <PasswordField<FormField>
                        control={form.control}
                        label={t('login_form_password')}
                        name="password"
                        placeholder={t('login_form_password')}
                    />
                </div>

                <div className="mb-6">
                    <Link
                        className="text-sm font-semibold text-neutral-50 underline"
                        href="/auth/forgot-password"
                    >
                        {t('login_forgot_password')}
                    </Link>
                </div>

                <Button
                    className="w-full justify-center"
                    loading={isPending}
                    size="lg"
                    type="submit"
                    variant="primary"
                >
                    {t('login_button')}
                </Button>
            </form>
        </Form>
    );
};

export default LoginForm;
