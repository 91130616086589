import * as z from 'zod';

import { type TFunction } from '@/i18n/client';

export const LoginSchema = (t?: TFunction<'auth'>) => {
    return z.object({
        email: z.string().email({
            message: t?.('field_email_required') ?? 'E-mail is verplicht',
        }),
        password: z.string().min(1, {
            message: t?.('field_password_required') ?? 'Wachtwoord is verplicht',
        }),
    });
};

export const passwordSchemaWithoutLength = (t?: TFunction<'auth'>) => {
    return z
        .string({ required_error: t?.('field_password_required') ?? 'Wachtwoord is verplicht' })
        .regex(/^(?=.*[A-Z])/, {
            message:
                t?.('field_password_capital') ??
                'Wachtwoord moet tenminste één hoofdletter bevatten',
        })
        .regex(/^(?=.*[a-z])/, {
            message:
                t?.('field_password_lowercase') ??
                'Wachtwoord moet tenminste één kleine letter bevatten',
        })
        .regex(/^(?=.*[0-9])/, {
            message:
                t?.('field_password_number') ?? 'Wachtwoord moet tenminste één cijfer bevatten',
        })
        .regex(/[-!@#$%^&*(),.?":{}|<>]/, {
            message:
                t?.('field_password_special') ??
                'Wachtwoord moet tenminste één speciaal karakter bevatten',
        });
};

const passwordSchemaWithLength = (t?: TFunction<'auth'>) => {
    return passwordSchemaWithoutLength(t).min(8, {
        message:
            t?.('field_password_min_length') ?? 'Password moet tenminste 8 karakters lang zijn',
    });
};

export const PasswordSchema = (t?: TFunction<'auth'>) => {
    return z
        .object({
            password: passwordSchemaWithLength(t),
            confirmPassword: passwordSchemaWithLength(t),
        })
        .refine((data) => data.password === data.confirmPassword, {
            message: t?.('field_password_mismatch') ?? 'Wachtwoorden komen niet overeen',
            path: ['confirmPassword'],
        });
};

export const ChangePasswordSchema = (t?: TFunction<'auth'>) => {
    return z.object({
        oldPassword: passwordSchemaWithLength(t),
        newPassword: passwordSchemaWithLength(t),
    });
};

export const ForgotPasswordSchema = (t?: TFunction<'auth'>) => {
    return z.object({
        email: z.string().email({ message: t?.('field_email_invalid') ?? 'E-mail is niet geldig' }),
    });
};

export const RegisterSchema = (t?: TFunction<'auth'>) => {
    return z.object({
        firstName: z.string().min(1, {
            message: t?.('field_first_name_required') ?? 'First name is required',
        }),
        lastName: z.string().min(1, {
            message: t?.('field_last_name_required') ?? 'Achternaam is verplicht',
        }),
        email: z.string().email({ message: t?.('field_email_invalid') ?? 'E-mail is niet geldig' }),
    });
};
